import React from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import ReactHtmlParser from 'react-html-parser';

const Page = ({ path, data: { node }, pageContext }) => {
  const title = node
    && node.title
    ? node.title
    : "no title"
  const content = node
    && node.body
    && node.body.processed
    ? ReactHtmlParser(node.body.processed)
    : "no content"
  return (
    <Layout>
      <Seo title={title} />
      <div id="page">
        <div className="content">

          <h1>{title}</h1>
          <div>{content}</div>
          <br />
        </div>
      </div>
    </Layout>
  );
};
export default Page;

export const query = graphql`
  query defaultPageQuery($id: String) {
    node: nodePage(id: { eq: $id }) {
      id
      drupal_id
      drupal_internal__nid
      path {
        alias
      }
      title
      created
      field_layout
      body {
        processed
        format
        summary
      }
    }
  }
`;
